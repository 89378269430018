import React from 'react';
import { Section, Link, VideoBlockItem } from 'components';
import * as styles from './styles.module.scss';

const VideoBlocks = ({ data }) => {
  const {
    primary: { title1: title, description, cta_text: ctaText, cta_link: ctaLink },
    items,
  } = data;

  return (
    <Section whiteBg>
      <div>
        <div className={styles.headings}>
          <h2>{title.text}</h2>
          <div className={styles.descriptions}>
            <div dangerouslySetInnerHTML={{ __html: description.html }} />
            <Link to={ctaLink.url} withArrow>
              {ctaText.text}
            </Link>
          </div>
        </div>
        <div className={styles.videoBlocks}>
          <div className={styles.primaryVideo}>
            {items.map((block) => {
              const { is_primary_block: isPrimaryBlock, wistia_video_id: wistiaVideoId } = block;
              if (isPrimaryBlock) {
                return <VideoBlockItem key={wistiaVideoId.text} data={block} />;
              }
            })}
          </div>
          <div className={styles.subVideos}>
            {items.map((block) => {
              const { is_primary_block: isPrimaryBlock, wistia_video_id: wistiaVideoId } = block;
              if (!isPrimaryBlock) {
                return <VideoBlockItem key={wistiaVideoId.text} data={block} />;
              }
            })}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default VideoBlocks;
