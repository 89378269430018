// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--fd239";
export var animateScrollOut = "styles-module--animateScrollOut--44183";
export var block = "styles-module--block--64522";
export var blockGroup = "styles-module--blockGroup--98bd7";
export var colorSequence = "styles-module--colorSequence--dd1df";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--a8c7e";
export var grow = "styles-module--grow--03c29";
export var growAndShrink = "styles-module--growAndShrink--55cb1";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--82e5a";
export var img = "styles-module--img--de4db";
export var link = "styles-module--link--38ff8";
export var moveBg = "styles-module--move-bg--0c4b4";
export var rotateLoop = "styles-module--rotateLoop--305c5";
export var section = "styles-module--section--7f331";
export var spin = "styles-module--spin--a82d5";
export var spinCounter = "styles-module--spinCounter--17744";