import React from 'react';
import { Section, Link, Image } from 'components';
import * as styles from './styles.module.scss';

const UpcomingEventsBlock = ({ data }) => {
  const {
    primary: { title1: title, cta_url: ctaUrl, cta_text: ctaText },
    items,
  } = data;

  return (
    <Section className={styles.section}>
      <div>
        <h3>{title.text}</h3>
        <div className={styles.blockGroup}>
          {items.map((block) => {
            const {
              event_date: eventDate,
              event_image: eventImage,
              event_link: eventLink,
              event_time: eventTime,
              event_title: eventTitle,
            } = block;

            return (
              <Link key={eventTitle.text} className={styles.block} to={eventLink.url}>
                <Image className={styles.img} image={eventImage} />
                <div>
                  <h5>{eventTitle.text}</h5>
                  <p>{eventDate.text}</p>
                  <p>{eventTime.text}</p>
                </div>
              </Link>
            );
          })}
        </div>
        <div className={styles.link}>
          <Link to={ctaUrl.url} withArrow>
            {ctaText.text}
          </Link>
        </div>
      </div>
    </Section>
  );
};

export default UpcomingEventsBlock;
